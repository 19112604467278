import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 1000px;

  .inner {
    @media (max-width: 100%) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--yellow);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 100%;

  @media (max-width: 100%) {
    margin: 50px auto 0;
    width: 100%;
  }

  .wrapper {
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--yellow);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(0%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--white);
      mix-blend-mode: screen;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'Continia OCR',
    'Dynamics 365 BC',
    'Dynamics NAV',
    'Dynamics Customizations (AL)',
    'Power BI',
    'Databricks',
    'Python',
    'Dotnet',
    'Golang',
    'Apache Spark',
    'Azure DevOps',
    'Kubernetes & Docker',
    'SQL Server',
    'Azure Functions',
    'Azure Logic Apps',
  ];
  const hyperlinks = [
    'https://www.continia.com/',
    'https://dynamics.microsoft.com/en-us/business-central/overview/',
    'https://community.dynamics.com/nav',
    'https://learn.microsoft.com/en-us/dynamics365/business-central/dev-itpro/developer/devenv-programming-in-al',
    'https://powerbi.microsoft.com/en-us/',
    'https://www.databricks.com/',
    'https://www.python.org/',
    'https://dotnet.microsoft.com/',
    'https://golang.org/',
    'https://spark.apache.org/',
    'https://azure.microsoft.com/en-us/services/devops/',
    'https://kubernetes.io/',
    'https://www.microsoft.com/en-us/sql-server',
    'https://azure.microsoft.com/en-us/services/functions/',
    'https://azure.microsoft.com/en-us/services/logic-apps/',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <div className="inner">
        <div className="text">
          <h2 className="numbered-heading">What We Do</h2>
          <StyledText>
            <div>
              <p>
                Our expert Application Architects, Business Analysts and Software Developers combine
                forces to form a decade of cumulative experience delivering Production solutions to
                meet any Software Integration/Customization, Application Security Permissions, Data
                Analytics, and Data Migration needs with:
              </p>

              <ul className="skills-list">
                {skills &&
                  skills.map((skill, i) => (
                    <li key={i}>
                      <a href={hyperlinks[i]}>{skill}</a>
                    </li>
                  ))}
              </ul>
              <br />
              <p>
                Our team of lean, tight-knit professionals pride in making every second - yours{' '}
                <em>and</em> ours - count. We have years of experience consulting with some of the
                largest Technology Consulting Firms, delivering complex software projects with
                Global Software Engineering Companies, and executing high-impact objectives for
                Enterprise Clients in North America.
              </p>
              <p>
                We deliver condensed, high-value technical solutions to set businesses up for
                success from the moment we engage:
              </p>
            </div>
          </StyledText>
        </div>
      </div>
      <div className="inner">
        <StyledPic>
          <StaticImage
            className="img"
            src="../../images/core-competence.png"
            width={1000}
            quality={100}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Our core competencies"
          />
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
