import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="I&R" transform="translate(11.000000, 5.000000)">
        <path d="M14.19,56.76v-19.6h3.8v19.6h-3.8Z" fill="currentColor" />
        <path
          d="M38.59,40.96h-6.2s-1.78,.03-1.78,1.88,1.78,1.88,1.78,1.88h10.39v3.8h-2.79v8.24h-7.77s-5.92-.02-5.92-6.02c0-2.3,1.04-3.54,2.02-4.28-.78-.68-1.56-1.74-1.56-3.62,0-5.68,5.79-5.68,5.79-5.68h6.04v3.8Zm-2.41,7.56h-3.87s-2.16,.09-2.16,2.22,2.16,2.22,2.16,2.22h3.87v-4.43Z"
          fill="currentColor"
        />
        <path
          d="M63.68,56.76l-3.92-7.9h-4.21v7.9h-3.8v-19.6h10.06s5.75,.13,5.75,5.85c0,3.47-2.12,4.89-3.78,5.46l4.12,8.29h-4.21Zm-8.13-11.7h5.71s2.46,.04,2.46-2.05-2.46-2.05-2.46-2.05h-5.71v4.09Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
