import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0;

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--yellow);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 800;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h2 {
    color: var(--white);
  }

  h3 {
    margin-top: 5px;
    color: var(--white);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  highlight {
    color: var(--yellow);
  }

  .tech-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Quality without compromise</h1>;
  const two = <h2 className="big-heading-gradient">I&R Tek Solutions.</h2>;
  const three = (
    <h3 className="medium-heading">
      Purpose-led tech<highlight>(k) </highlight> consulting.
    </h3>
  );
  const four = (
    <>
      <p>
        We are a team of Canadians specializing in Microsoft Dynamics 365 & Azure, Data Analytics &
        Visualization, and Open-Source Software.
      </p>

      <p>
        Our team strives to maintain 2 primary objectives:
        <ol>
          <li>Fulfill business needs using technology as a means to an end</li>
          <li>
            Deliver high-quality technical solutions with Software Engineering best-practices of
            Infrastructure Automation, Automated Testing and Security.
          </li>
        </ol>
      </p>
    </>
  );
  const five = (
    <a className="tech-link" href="#tech" target="_self" rel="noreferrer">
      Check out our work
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
